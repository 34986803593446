<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <product ref="product" @getproduct="getproduct" :fromType="fromType" :titleName="titleName" :tempType="tempType" :isDialog="true"></product>
  </el-dialog>
</template>
<script>
  import product from '@/views/common/product.vue'
  export default {
    name: 'productList',
    components: {
      product
    },
    props:{
      fromType: {
        type: String,
        default: ''
      },
      titleName: {
        type: String,
        default: '供应商产品'
      },
      tempType: {
        type: String,
        default: '02' //01为产品 02为供应商
      },
    },
    data() {
      return {
        closeFlag: false,
        loading: false,
        title: '主体产品',
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
      initData(){
        this.closeFlag = true
        this.$refs.product && this.$refs.product.reTable()
      },
      closeModel() {
        this.closeFlag = false
      },
      getproduct(data){
        this.$emit('getproductlist', data)
        this.closeModel()
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>