<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="800px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input v-else :disabled="true" class="my-input-btn" v-model="ruleForm.productName" placeholder="请选择产品名称">
                  <el-button slot="append" @click="chooseType" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" v-if="editType == 'edit'">
              <el-form-item label="产品批次档案号" prop="docNum">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.docNum }}</p>
                <el-input clearable v-else :disabled="true" v-model="ruleForm.docNum"  placeholder="请选择产品批次档案号" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="开始生产日期：" prop="startProductionDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.startProductionDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.startProductionDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择开始生产日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="基地信息：" prop="baseId">
                <el-select v-model="ruleForm.baseId" @change="baseChange" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择基地信息'" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.plots"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="地块信息：" prop="plotId">
                <el-select v-model="ruleForm.plotId" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择地块信息'" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.bases"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="defaultObj.allProductType == '02' || defaultObj.allProductType == '03'">
            <el-col :span="24">
              <el-form-item label="进苗数量：" prop="yield">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.yield || '') + (ruleForm.yieldUnit || '')}}</p>
                <el-input clearable v-else v-model="ruleForm.yield" placeholder="请输入进苗数量" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.yieldUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.numUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="预产量（吨）：" prop="yield">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.yield }}</p>
                <el-input clearable v-else v-model="ruleForm.yield" placeholder="请输入预产量（吨）" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="预计收获/出栏日期：" prop="harvestDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.harvestDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.harvestDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择预计收获/出栏日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="预计结束收获日期：" prop="endHarvestDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.endHarvestDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.endHarvestDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择预计结束收获日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="生产周期：" prop="productionPeriod">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productionPeriod }}</p>
                <el-input clearable v-else v-model="ruleForm.productionPeriod" placeholder="请输入生产周期" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="主要肥料/饲料：" prop="fodder">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.fodder }}</p>
                <el-input clearable v-else v-model="ruleForm.fodder" placeholder="请输入主要肥料/饲料" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="主要防治对象：" prop="object">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.object }}</p>
                <el-input clearable v-else v-model="ruleForm.object" placeholder="请输入主要防治对象" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="主要防治方法：" prop="prevention">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.prevention }}</p>
                <el-input clearable v-else v-model="ruleForm.prevention" placeholder="请输入主要防治方法" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="质量安全负责人：" prop="safetyOfficer">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.safetyOfficer }}</p>
                <el-input clearable v-else v-model="ruleForm.safetyOfficer" placeholder="请输入质量安全负责人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="档案记录人：" prop="docRecorder">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.docRecorder }}</p>
                <el-input clearable v-else v-model="ruleForm.docRecorder" placeholder="请输入档案记录人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <product-list ref="product" @getproductlist="getproductlist"></product-list>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import productList from './productList.vue'
export default {
  name: "batchEdit",
  components: {
    productList
  },
  props:{
  },
  data() {
    return {   
      rules: {
        productName: [{ required: true,  trigger: "change", message: '请选择产品名称'}],
        startProductionDate: [{ required: true,  trigger: "change", message: '请选择开始生产日期'}],
        yield: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入预产量（吨）"))

            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        num: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],

      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      selectObj: {
        numUnit: CONSTPARAMS.numUnit,
        plots: [],
        bases: []
      },
      baseplots: [],
      defaultObj: {}
    };
  },
  created() {
    this.getbaseplot()
	},
  methods: {
    getbaseplot(){
      let _this = this
      request.findbaseplot({
        bodyId: utils.getBodyId()
      }).then(res => {
        if(res.code == 200){
          let all = res.data || [], arr = []
          all.forEach(cur => {
            arr.push({
              label: cur.baseName,
              value: cur.baseId,
            })
          })
          _this.selectObj.plots = arr
          _this.baseplots = all
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    baseChange(val){
      console.log(val)
      this.ruleForm.plotId = ''
      this.getPlot(val)
    },
    getPlot(id){
      let arr = [],
        _this = this,
        baseplots = this.baseplots || []
      if(id && baseplots.length){
        baseplots.forEach(cur => {
          if(cur.baseId == id){
            let newArr = cur.plot || []
            if(newArr.length){
              newArr.forEach(cur => {
                arr.push({
                  label: cur.plotName,
                  value: cur.plotId,
                })
              })
            }
          }
        })
      }
      _this.selectObj.bases = arr
    },
    getBaseName(){
      let plotId = this.ruleForm.baseId,
        plots = this.selectObj.plots || []
      return utils.getSelLabel(plots, plotId)
    },
    getPlotName(){
      let plotId = this.ruleForm.plotId,
        plots = this.selectObj.bases || []
      return utils.getSelLabel(plots, plotId)
    },
    getproductbatch(id){
      let _this = this
      request.apfindproductbatch({
        docId: id || ''
      }).then(res => {
        if(res.code == 200){
          _this.defaultObj = res.data || {}
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getproductlist(data){
      this.ruleForm.productName = data.productName || ''
      this.ruleForm.productId = data.productId || ''
      this.getproductbatch(data.docId)
    },
    chooseType(){
      this.$refs.product.initData()
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = ruleForm
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
      this.getPlot(this.ruleForm.baseId)
    },
    initLook(){
      this.getPlot(this.ruleForm.baseId)
    },
    submitForm(formName) {
      let _this = this,
        editType = this.editType;
      let mesInfo = {
        add: "新增成功！",
        edit: "修改成功！"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'saveproductbatch' : 'updateproductbatch',
            target = {
            ..._this.ruleForm,
            startProductionDate: utils.dateFormat(_this.ruleForm.startProductionDate),
            harvestDate: utils.dateFormat(_this.ruleForm.harvestDate),
            endHarvestDate: utils.dateFormat(_this.ruleForm.endHarvestDate),
            baseName: _this.getBaseName(),
            plotName: _this.getPlotName()
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              _this.closeModel()
              _this.$emit("getData", editType == 'add' ? true : false)
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


