<template>
  <div :class="isDialog ? '' : 'page-table'">
    <div class="title-search my-table-list">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <div class="title-search-line">
          <el-form-item label="产品名称:">
            <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="开始生产日期:">
            <el-date-picker
              class="my-date-range"
              v-model="timeRange"
              @change="timeChange"
              type="daterange"
              align="right"
              unlink-panels
              size="small"
              range-separator="到"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          <el-button  v-if="isDialog" type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </el-form>
    </div>
    <div class="title-tab" v-if="!isDialog">
      <div class="title-tab-btns">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
      </div>
    </div>
    <el-table 
      ref="table" 
      class="my-table"
      size="small" 
      :data="listData" 
      highlight-current-row 
      border
      v-loading="loading"  
      :element-loading-text="loadtext" 
      :height="isDialog ? 'calc(100vh - 367px)' : tableHeight"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <el-table-column align="center" type="index" width="70" label="序号">
        <template slot-scope="scope">
          <span>{{
            pageparm
              ? (pageparm.page - 1) * pageparm.limit +
                scope.$index +
                1
              : scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      
      <el-table-column
        v-for="(headData, index) in tableHead"
        :key="index"
        :prop="headData.prop"
        :label="headData.label"
        :width="headData.width"
        :align="headData.align || 'center'"
        :header-align="headData.headerAlign || 'center'"
        :min-width="headData.minWidth"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">
          {{ scope.row[headData.prop] || '' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button v-if="isDialog" size="small" type="text" @click="choose(scope.row, scope.$index)">选择</el-button>
          <el-dropdown v-else class="my-drop-down" trigger="click">
            <span class="el-dropdown-link">
              编辑档案<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="editDoc(scope.row, 'drugsEdit', '药品使用记录')">药品使用记录</el-dropdown-item>
              <el-dropdown-item @click.native="editDoc(scope.row, 'fodderEdit', '肥料/饲料使用记录')">肥料/饲料使用记录</el-dropdown-item>
              <el-dropdown-item @click.native="editDoc(scope.row, 'testEdit', '检验检测记录')">检验检测记录</el-dropdown-item>
              <el-dropdown-item @click.native="editDoc(scope.row, 'harvestEdit', '收获记录')">收获记录</el-dropdown-item>
              <el-dropdown-item @click.native="editDoc(scope.row, 'farmEdit', '日常农事或加工记录')">日常农事或加工记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
          <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
          <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    <batch-edit ref="editForm" @getData="getData"></batch-edit>
    <farmEdit ref="farmEdit" @getData="getData" :hasPid="true"></farmEdit>
    <drugs-edit ref="drugsEdit" @getData="getData" :hasPid="true"></drugs-edit>
    <fodder-edit ref="fodderEdit" @getData="getData" :hasPid="true"></fodder-edit>
    <harvest-edit ref="harvestEdit" @getData="getData" :hasPid="true"></harvest-edit>
    <test-edit ref="testEdit" @getData="getData" :hasPid="true"></test-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import batchEdit from './batchEdit.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  import * as XLSX from "xlsx"
  let title = ''
  export default {
    name: 'batch',
    components: {
      farmEdit: () => import('../docs/farmEdit.vue'),
      drugsEdit: () => import('../docs/drugsEdit.vue'),
      fodderEdit: () => import('../docs/fodderEdit.vue'),
      harvestEdit: () => import('../docs/harvestEdit.vue'),
      testEdit: () => import('../docs/testEdit.vue'),
      pagination,
      batchEdit,
    },
    props:{
      titleName: {
        type: '',
        default: ''
      },
      isDialog: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        timeRange: '',
        pickerOptions: {
          shortcuts: [
          {
            text: '最近一月',
            onClick(picker) {
              let obj = utils.getDays('1')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }, {
            text: '最近一季',
            onClick(picker) {
              let obj = utils.getDays('3')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }, {
            text: '最近一年',
            onClick(picker) {
              let obj = utils.getDays('12')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }]
        },
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          productName: '',
          startTime: '',
          endTime: '',
        },
        urlName: 'findallproductbatch',
        tableHead: [{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'docNum',
          label: '批次编号',
          width: 140
        },{
          prop: 'yield',
          label: '预产量',
          width: 100
        },{
          prop: 'startProductionDate',
          label: '开始生产日期',
          width: 110
        },{
          prop: 'productionPeriod',
          label: '生产周期',
          width: 100
        },{
          prop: 'object',
          label: '防治对象',
          width: 100
        },{
          prop: 'safetyOfficer',
          label: '质量安全负责人',
          width: 140
        },{
          prop: 'docRecorder',
          label: '档案记录人',
          width: 140
        }],
        initForm: {
          bodyId: utils.getBodyId(),
          bodyName: utils.getBodyName(),
          docId:'',
          productName:'',
          productId:'',
          startProductionDate:'',
          baseName:'',
          baseId:'',
          plotName:'',
          plotId:'',
          num:'',
          numUnit:'条',
          yield:'',
          harvestDate:'',
          endHarvestDate:'',
          productionPeriod:'',
          fodder:'',
          prevention:'',
          object:'',
          safetyOfficer:'',
          docRecorder:''
        },
        loadtext: '加载中',
        pageName: title,
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
      this.pageName = this.titleName
    },
    methods: {
      choose(data, index){
        this.$emit('getbatch', data)
      },
      reTable(){
        this.$nextTick(() => {
          this.$refs.table &&  this.$refs.table.doLayout()
        })
      },
      download(){
        let arr = this.listData,
        tableData = [['序号', '产品名称', '批次编号', '预产量', '开始生产日期', "生产周期", "防治对象",  "质量安全负责人",  "档案记录人"]] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', "warning")
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.productName,
            item.docNum,
            item.yield,
            item.startProductionDate,
            item.productionPeriod,
            item.object,
            item.safetyOfficer,
            item.docRecorder
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0], 'yyyy-MM-dd HH:mm:ss')
          endTime = utils.dateFormat(val[1], 'yyyy-MM-dd HH:mm:ss')
        }
        this.searchForm.startTime = startTime 
        this.searchForm.endTime = endTime 
      },
      editDoc(data, name, title){
        let obj = {}
        if(name == 'drugsEdit'){
          obj = {
            docNum: data.docNum,
            productName: data.productName,
            doceId: data.docId,
          }
        }else{
          obj = {
            docNum: data.docNum,
            productName: data.productName,
            docId: data.docId,
          }
        }
        this.$refs[name].initData(`新增${title}`, 'add', {}, obj)
      },
      async initTable(){
        this.getData()
      },
      async add(){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          docId: id
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.findproductbatch({
          docId: data.docId || ''
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.bodyName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.deleteproductbatch({
            docId: data.docId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                startProductionDate: utils.dateFormat(item.startProductionDate, 'yyyy-MM-dd')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
.my-drop-down{
  color: #f16123;
  font-size: 12px;
  padding-right: 10px;
  cursor: pointer;
}
</style>